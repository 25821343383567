










































































































import moment from 'moment'
import {Component, Emit, Prop, Vue} from 'vue-property-decorator'
import {InputCheckbox, InputText} from '@simpli/vue-input'
import {DappCollection} from '@/model/collection/DappCollection'
import {FilterDappSchema} from '@/schema/resource/Dapp/FilterDappSchema'

export const transactionOptions = [
  {
    text: 'schema.FilterDapp.lastDay',
    value: moment()
      .subtract(1, 'days')
      .format(),
  },
  {
    text: 'schema.FilterDapp.lastFortnight',
    value: moment()
      .subtract(15, 'days')
      .format(),
  },
  {
    text: 'schema.FilterDapp.lastWeek',
    value: moment()
      .subtract(7, 'days')
      .format(),
  },
  {
    text: 'schema.FilterDapp.lastMonth',
    value: moment()
      .subtract(30, 'days')
      .format(),
  },
]

@Component({
  components: {InputCheckbox, InputText},
})
export default class FilterTransactionView extends Vue {
  @Prop({required: true}) collection!: DappCollection
  @Prop({required: true}) schema!: FilterDappSchema
  transactionFocused = false
  transactionOptions = transactionOptions

  get disableApplyFilterButton() {
    return this.collection.min == null && this.collection.max == null
  }

  mounted() {
    document.body.addEventListener('click', this.clickOutsideEvent)
  }

  beforeDestroy() {
    document.body.removeEventListener('click', this.clickOutsideEvent)
  }

  selectTimeItem(value: string) {
    this.collection.date = value
  }

  @Emit('filter')
  doFilter() {
    this.transactionFocused = false
  }

  @Emit('filter')
  doClear() {
    this.collection.min = null
    this.collection.max = null
    this.collection.date = null
    this.transactionFocused = false
  }

  clickOutsideEvent(event: MouseEvent) {
    if (
      !(
        this.$refs.transactionArea === event.target ||
        (this.$refs.transactionArea as HTMLDivElement).contains(
          event.target as Node
        )
      )
    ) {
      this.transactionFocused = false
    }
  }
}
