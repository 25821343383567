/**
 * ContractNep17Collection
 * @author Lopes
 */
import {HttpExclude, Request} from '@simpli/serialized-request'
import {PageCollection} from '@simpli/resource-collection'
import {ContractNep17} from '@/model/resource/ContractNep17'

@HttpExclude()
export class ContractNep17Collection extends PageCollection<ContractNep17> {
  constructor() {
    super(ContractNep17)
  }

  resource?: IContractNep17CollectionResourcesHolder

  queryAsPage() {
    return this.listContractNep17()
  }

  async listContractNep17() {
    return await Request.get(`/client/contract-nep-17`, {
      params: this.params,
    })
      .name('listContractNep17')
      .as(this)
      .getResponse()
  }
}

export interface IContractNep17CollectionResourcesHolder {}
