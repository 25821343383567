/**
 * Filter Schema of Contract
 * @author Simpli CLI generator
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-input'
import {IContractCollectionResourcesHolder} from '@/model/collection/ContractCollection'
import {Contract} from '@/model/resource/Contract'
import {DappCollection} from '@/model/collection/DappCollection'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {EnvHelper} from '@/helpers/EnvHelper'
import SelectNullableBoolean from '@/components/SelectNullableBoolean.vue'
import SelectMultipleDapps from '@/components/SelectMultipleDapps.vue'
import {PriceHistoryCollection} from '@/model/collection/PriceHistoryCollection'
import {ContractNep17Collection} from '@/model/collection/ContractNep17Collection'

export class FilterContractSchema extends DefaultSchema
  implements IContractCollectionResourcesHolder {
  collectionDapp = new DappCollection().noPagination()
  collectionBlockchainVersion = new BlockchainVersionCollection().noPagination()
  collectionPriceHistory = new PriceHistoryCollection().noPagination()
  collectionContractNep17 = new ContractNep17Collection().noPagination()

  readonly name = 'FilterContract'

  readonly fieldSet: FieldSet<Contract> = {
    pricedToken: (schema): FieldComponent => ({
      is: SelectNullableBoolean,
      bind: {
        label: this.translateFrom(schema.fieldName),
        nullLabel: this.translateFrom('pricedTokenOptions.null'),
        trueLabel: this.translateFrom('pricedTokenOptions.true'),
        falseLabel: this.translateFrom('pricedTokenOptions.false'),
      },
    }),
    token: (schema): FieldComponent => ({
      is: SelectNullableBoolean,
      bind: {
        label: this.translateFrom(schema.fieldName),
        nullLabel: this.translateFrom('tokenOptions.null'),
        trueLabel: this.translateFrom('tokenOptions.true'),
        falseLabel: this.translateFrom('tokenOptions.false'),
      },
    }),
    blockchainVersion: (schema): FieldComponent => ({
      is: Component.InputSelect,
      bind: {
        items: this.collectionBlockchainVersion.items,
        hideSelected: false,
        closeOnSelect: false,
        preserveSearch: true,
        label: this.translateFrom(schema.fieldName),
        trackBy: '$id',
        class: {hidden: EnvHelper.VUE_APP_N3_HIDE},
      },
    }),
    startDeploymentDate: (schema): FieldComponent => ({
      is: Component.InputDate,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    endDeploymentDate: (schema): FieldComponent => ({
      is: Component.InputDate,
      bind: {
        label: this.translateFrom(schema.fieldName),
      },
    }),
    active: (schema): FieldComponent => ({
      is: SelectNullableBoolean,
      bind: {
        label: this.translateFrom(schema.fieldName),
        nullLabel: this.translateFrom('activeOptions.null'),
        trueLabel: this.translateFrom('activeOptions.true'),
        falseLabel: this.translateFrom('activeOptions.false'),
      },
    }),
    withDapp: (schema): FieldComponent => ({
      is: SelectNullableBoolean,
      bind: {
        label: this.translateFrom(schema.fieldName),
        nullLabel: this.translateFrom('withDappOptions.null'),
        trueLabel: this.translateFrom('withDappOptions.true'),
        falseLabel: this.translateFrom('withDappOptions.false'),
      },
    }),
    dapp: (schema): FieldComponent => ({
      is: SelectMultipleDapps,
      bind: {
        collection: this.collectionDapp,
        label: this.translateFrom(schema.fieldName),
        cleanable: false,
      },
    }),
  }
}
