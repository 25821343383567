










































import {Component, Prop, Watch, Vue} from 'vue-property-decorator'
import {ContractCollection} from '@/model/collection/ContractCollection'
import {FilterContractSchema} from '@/schema/resource/Contract/FilterContractSchema'

@Component
export default class FilterContract extends Vue {
  @Prop({type: Object, required: true}) collection!: ContractCollection

  schema = new FilterContractSchema()

  async created() {
    await this.populateFilterOptions()
  }

  async populateFilterOptions() {
    this.collection.resource = this.schema

    const promises: Array<Promise<any>> = [
      this.collection.resource.collectionBlockchainVersion.queryAsPage(),
      this.collection.resource.collectionContractNep17.queryAsPage(),
    ]

    await this.$await.run('softQuery', () => Promise.all(promises))
  }

  async doFilter() {
    this.collection.setCurrentPage(0)
    this.$emit('submit')
    await this.$await.run('softQuery', () => this.collection.queryAsPage())
  }
}
