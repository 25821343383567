



































































































































































































































































































import {Component, Prop, Watch, Provide, Vue} from 'vue-property-decorator'
import {Dapp} from '@/model/resource/Dapp'
import {InputDappSchema} from '@/schema/resource/Dapp/InputDappSchema'
import {CategoryCollection} from '@/model/collection/CategoryCollection'
import {Category} from '@/model/resource/Category'
import {Multiselect} from 'vue-multiselect'
import FilterDappFiltered from '@/components/filters/FilterDappFiltered.vue'
import ListDappSocialView from '@/views/list/ListDappSocialView.vue'
import ListSocialTypeView from '@/views/list/ListSocialTypeView.vue'
import {Token} from '@/model/resource/Token'
import {NntTag} from '@/model/resource/NntTag'
import {Wallet} from '@/model/resource/Wallet'
import DappContractList from '@/components/DappContractList.vue'

@Component({
  components: {
    DappContractList,
    ListSocialTypeView,
    ListDappSocialView,
    Multiselect,
    FilterDappFiltered,
  },
})
export default class PersistDappView extends Vue {
  @Prop() id?: string

  @Provide('validator') validator = this.$validator

  schema = new InputDappSchema()
  dapp = new Dapp()
  isInvalid = false

  collectionCategory = new CategoryCollection().noPagination()

  async created() {
    await this.populateResource()
    await this.populate()
  }

  populateResource() {
    this.dapp.inActiveDevelopment = true
    this.dapp.detailed = true
    this.collectionCategory.queryAsPage()
    this.schema.collectionNntTag.queryAsPage()
    this.schema.collectionToken.queryAsPage()
    this.schema.collectionWallet.queryAsPage()
    this.schema.collectionBlockchain.queryAsPage()
    this.schema.collectionPrivateCategory.queryAsPage()
  }

  async tagEvent(field: string, val: string) {
    if (!val) {
      return
    }
    switch (field) {
      case 'dappCategory': {
        const category = new Category()
        category.title = val
        category.active = true
        category.idCategoryPk = await category.persistCategory()
        await this.collectionCategory.queryAsPage()
        this.dapp.dappCategory.push(category)
        break
      }
      case 'dappToken': {
        const token = new Token()
        token.title = val
        token.active = true
        token.idTokenPk = await token.persistToken()
        await this.schema.collectionToken.queryAsPage()
        this.dapp.dappToken.push(token)
        break
      }
      case 'dappNntTag': {
        const tag = new NntTag()
        tag.title = val
        tag.active = true
        tag.idNntTagPk = await tag.persistNntTag()
        await this.schema.collectionNntTag.queryAsPage()
        this.dapp.dappNntTag.push(tag)
        break
      }
      case 'dappWallet': {
        const wallet = new Wallet()
        wallet.title = val
        wallet.active = true
        wallet.idWalletPk = await wallet.persistWallet()
        await this.schema.collectionWallet.queryAsPage()
        this.dapp.dappWallet.push(wallet)
        break
      }
    }
  }

  async populate() {
    const id = Number(this.id) || null

    if (id) {
      await this.dapp.getDapp(id)
    }

    this.$await.done('getDapp')
  }

  clearAll() {
    this.dapp.dappToken = []
    this.dapp.dappCategory = []
    this.dapp.dappNntTag = []
    this.dapp.dappWallet = []
  }

  async persist() {
    const isValid = await this.validator.validateAll()

    if (!isValid) {
      this.isInvalid = true
      this.$toast.abort('system.error.validation')
    }
    this.isInvalid = false

    const id = await this.dapp.persistDapp()
    this.$root.$emit('dappSaved', id)
    this.$toast.success('system.success.persist')
    await this.$nav.push('/dapp/list')
  }
}
